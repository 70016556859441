import styled from 'styled-components';

import { media } from 'styles/mixins';

const Content = styled.div`
  ${media.mobileM`
    row-gap: 3rem !important; // override HorizontalContainer styles
  `}

  img {
    max-width: 15.625rem;

    ${media.mobileM`
      max-width: 9.375rem;
    `}
  }

  > div {
    max-width: 31.25rem;
  }
`;

export { Content };
