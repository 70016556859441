import { graphql } from 'gatsby';

import Social from 'components/Social';
import { PageWrapper } from 'components/Layout/PageWrapper';
import { HorizontalContainer } from 'components/UI/Containers';
import { HeadingBig, SubHeading } from 'components/UI/Headings';

import { Content } from './styles';

const PaymentResultPageTemplate = ({ data, pageContext }) => {
  const {
    datoCmsPaymentResultPage: pageData,
    datoCmsFooter: { contacts: networks },
  } = data;
  const {
    successTitle,
    successDescription,
    successImage,
    failTitle,
    failDescription,
    failImage,
    facebookPixel,
  } = pageData;
  const isFailed = pageContext.failed;

  const title = isFailed ? failTitle : successTitle;
  const image = isFailed ? failImage : successImage;
  const description = isFailed ? failDescription : successDescription;

  return (
    <PageWrapper
      centered
      pageData={pageContext}
      seoTitle={title}
      seoDescription={description}
      pageScript={facebookPixel}
    >
      <HorizontalContainer>
        <div />
        <section>
          <Content>
            <img src={image.url} alt={image.alt} />
            <div>
              <HeadingBig>{title}</HeadingBig>
              <SubHeading>{description}</SubHeading>
              <Social networks={networks} />
            </div>
          </Content>
        </section>
      </HorizontalContainer>
    </PageWrapper>
  );
};

export default PaymentResultPageTemplate;

export const query = graphql`
  query PaymentResultPageQuery($locale: String!) {
    datoCmsPaymentResultPage(locale: { eq: $locale }) {
      locale
      successTitle
      successDescription
      successImage {
        url
        alt
        gatsbyImageData
      }
      failTitle
      failDescription
      failImage {
        url
        alt
        gatsbyImageData
      }
      facebookPixel
    }
    datoCmsFooter(locale: { eq: $locale }) {
      contacts {
        fieldName
        isSocial
        links {
          id: originalId
          fieldName
          fieldValue
        }
      }
    }
  }
`;
