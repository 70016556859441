import styled from 'styled-components';

const Wrapper = styled.div`
  p {
    margin-bottom: 0.25rem;
  }

  ul {
    display: grid;
    column-gap: 1.5rem;
    grid-auto-flow: column;
    width: fit-content;

    li a {
      font-size: 1rem;
      font-weight: var(--weight-medium);
    }
  }
`;

export { Wrapper };
