import { Fragment } from 'react';

import { Wrapper } from './styles';

const Content = ({ networks }) => (
  <Wrapper>
    {networks.map((network, i) => {
      const { isSocial, fieldName, links } = network;

      return (
        isSocial && (
          <Fragment key={i}>
            <p>{fieldName}</p>
            <ul>
              {links.map((link) => (
                <li key={link.id}>
                  <a
                    href={link.fieldValue}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {link.fieldName}
                  </a>
                </li>
              ))}
            </ul>
          </Fragment>
        )
      );
    })}
  </Wrapper>
);

export default Content;
